/** @jsx jsx */
import React from "react";
import { BlockquoteData } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from '@emotion/react'

import {
blockQuote,
blockTitle
} from "../assets/styles/Blockquote.styles";
import {
    secTitle,
    secDesk,
    commonSection,
  } from "../assets/styles/layout.styles";

  const Blockquote = () => {
    const { vector, blockquote, title, desc, smallBlockQuote } = BlockquoteData;
    return (
      <section css={[commonSection,blockQuote]}>
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12}>            
                <div css={blockTitle}
                data-aos-offset={"0"}
                data-aos={"fade"} 
                data-aos-duration={"1000"}  
                data-aos-delay={"200"} 
                data-aos-once={true} 
                >
                  <img src={vector} className="vector" />
                  <h2 css={secTitle}>
                    {title}
                    <img src={smallBlockQuote} className="smallBQ" />
                  </h2>
                  <img src={blockquote} className="blockquote" />
                </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
                <p css={secDesk}
                  data-aos-offset={"0"}
                  data-aos={"fade"} 
                  data-aos-duration={"1000"}  
                  data-aos-delay={"400"} 
                  data-aos-once={true}
                >{desc}</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  };
  
  export default Blockquote;