/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "./layout.styles";

export const who = css`
  padding: 120px 0px;

  h2 {
    font-size: 48px;
    margin-bottom: 64px;
  }

  img {
    width: 100%;
  }

  ul {
    display: flex;
    margin-bottom: 64px;
    li {
      display: inline-block;
      width: 33.3%;
      padding: 0 20px;

      &:nth-child(2) {
        div img {
          width: 100px;
        }
      }

      &:nth-child(3) {
        div img {
          width: 81px;
        }
      }
      div {
        text-align: center;
        background-color: ${colors.green};
        color: #ffffff;
        border-radius: 12px;
        border: 3px solid #c7e3ba;
        height: 192px;
        position: relative;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: 0.3s;
        img {
          width: 124px;
          margin-bottom: 10px;
          transition: 0.3s;
        }
        span {
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
          margin: 0;
          transition: 0.3s;
        }

        &:hover {
          background-color: ${colors.yellow};
          border-color: #fdf2b4;
          span {
            color: #000000;
          }
          img {
            filter: brightness(0);
          }
        }
      }
    }
  }

  p {
    text-align: center;
    width: 85%;
    margin: 0 auto;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0 0 60px;
    h2 {
      font-size: 24px;
    }
    ul {
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 0;
      li {
        width: 100%;
        margin-bottom: 32px;
      }
    }
    p{
      text-align: left;
      font-size: 18px;
      line-height: 22.5px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;

export const blockTitle = css``;
