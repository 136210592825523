/** @jsx jsx */
import React from "react";
import { HowData, PartnersImage } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { Link } from "gatsby";

import { partners, logos } from "../assets/styles/Partners.styles";
import {
  secTitle,
  commonSection,
  commonBtn,
  blueBg,
} from "../assets/styles/layout.styles";

const Partners = () => {
  const { img1 } = PartnersImage;
  return (
    <section css={[commonSection, partners]}>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <h2 css={secTitle}>Seja um parceiro financiador</h2>
            <p>
            Aproxime sua marca de uma causa que faz bem para todos. Seja parceiro da OPMbr e contribua para acelerar o desenvolvimento do país.
            </p>
           <Link css={[commonBtn,blueBg]} to="/">APOIE</Link>
          </Col>
          <Col lg={6} md={6} sm={12}>
             <div css={logos}>
              <img src={img1} alt="partners" />
             </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
