/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "./layout.styles";

export const how = css`
  padding: 120px 0px 0;

  h2 {
    font-size: 48px;
    margin-bottom: 32px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 60px 0px 0px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }

  img {
    width: 100%;
  }
`;

export const blockTitle = css``;
