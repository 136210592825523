/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "./layout.styles";

export const newsletter = css`
  padding: 80px 0 0;
  background: linear-gradient(to left, #6eb64c, #aad496);
  display: flex;
  align-items: center;

  .books {
    mix-blend-mode: multiply;
    position: absolute;
    right: 0;
  }

  .lwb {
    right: 40%;
    position: absolute;
    z-index: 1;
  }

  .rwb {
    right: 5%;
    position: absolute;
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 40px;
    height: auto;
    align-items: flex-start;
    flex-direction: column-reverse;

    .books {
      position: relative;
      width: 100%;
    }
    .lwb {
      left: 5%;
    top: 50px;
    position: absolute;
    z-index: 1;
    width: 15%;
    }
    .rwb {
      width: 15%;
      right: 5%;
      top:50px;
      position: absolute;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }

  @media (min-width: 992px) and (max-width: 1366px) {
    .books {
      width: 700px;
    }
  }

  @media (min-width: 1440px) {
    .books {
      right: 5%;
    }
  }

`;

export const pr79 = css`
  padding-bottom: 80px;
  h2 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  img {
    margin-bottom: 52px;
  }

  p {
    margin-bottom: 24px;
  }

  form {
    width: 90%;
    button {
      margin-top: 32px;
      padding: 28px 32px;
    }
  }

  input {
    padding: 2rem 0.75rem;
    border-color: ${colors.yellow};
    color: ${colors.dark};
    border-radius: 8px;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-ms-input-placeholder,
    &::-moz-placeholder {
      color: ${colors.dark};
      font-size: 18px;
    }
  }

  .professores_mobile {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
    }
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
    p {
      font-size: 18px;
      line-height: 20px;
    }
    form {
      width: 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    img {
      display: block;
      margin: 0 auto;
    }
    form {
      width: 100%;
    }
  }
`;

export const mb45 = css`
  margin-bottom: 45px;
  @media (min-width: 768px) and (max-width: 990px) {
    margin-bottom: 30px;
  }
`;

export const abImg = css`
  img {
  }

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
`;
