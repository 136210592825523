/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "./layout.styles";

export const partners = css`
  padding: 64px 0px;

  h2{
    font-size: 40px;
    margin-bottom: 32px;
    text-transform: uppercase;
  }


  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0px 0px 60px;
    h2{
      font-size: 24px;
      line-height: 36px;
      margin: 32px 0;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0;
    }
    a{
      margin: 16px 0;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }

  img{
    width: 100%;
  }
`;

export const logos = css`
    img{
      margin-bottom: 32px;
    }
`;
