/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "./layout.styles";

export const video = css`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  width:100%;
  margin: 80px 0;
  border-radius: 10px;
  margin-left:15px;
  margin-right:15px;

  iframe{
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
  }
`
