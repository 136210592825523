/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "./layout.styles";

export const abAgency = css`
  height: 100vh;
  padding: 80px 0 0;
  background-color: ${colors.gray};
  display: flex;
    align-items: center;

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-top:70px;
    height: auto;
    align-items: flex-start;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;

export const pr79 = css`
  padding-bottom: 80px;

  img {
    margin-bottom: 52px;
  }

  p{
    margin-bottom: 24px;
  }

  form {
    width: 90%;
    button {
      margin-top: 32px;
      padding: 28px 32px;
    }
  }

  input {
    padding: 2rem 0.75rem;
    border-color: ${colors.green};
    color: ${colors.dark};
    border-radius: 8px;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-ms-input-placeholder,
    &::-moz-placeholder {
      color: ${colors.dark};
      font-size: 18px;
    }
  }

  .professores_mobile{
    display: none;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .professores_mobile{
    display: block;
    width: 100%;
  }
    text-align: center;
    img{
      display: block;
      margin: 0 auto;
    }
    h2{
      font-size: 24px;
      line-height: 30px;
    }
    p{
      font-size: 18px;
      line-height: 20px;
    }
    form{
      width: 100%;
    }
    
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .professores_mobile{
    display: block;
  }
    img{
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    form{
      width: 100%;
    }
  }
`;

export const mb45 = css`
  margin-bottom: 45px;
  @media (min-width: 768px) and (max-width: 990px) {
    margin-bottom: 30px;
  }
`;

export const abImg = css`
  position: absolute;
  right: 30px;
  top: 0;
  z-index: 1;
  /* background-color: #ddd; */
  width: 50%;
  height: 100%;
  img {
    position: absolute;
    &.prof1 {
      bottom: 0;
      left: 0px;
      width: 72%;
      z-index: 1;
    }
    &.prof2 {
      bottom: 0;
      right: 65px;
      position: absolute;
      width: 57%;
      z-index: 2;
    }
    &.vetor1 {
      bottom: 184px;
      right: 0px;
      z-index: 0;
    }
    &.vetor2 {
      bottom: 0;
      right: 0;
    }
    &.vetor3 {
      bottom: 35px;
      left: -35px;
      z-index: 0;
    }
  }

  /* &:nth-child(2) {
    position: relative;
    top: 30px;
    margin-top: 0;
    &:after {
      @media (min-width: 768px) and (max-width: 990px) {
        width: 350px;
        left: 15px;
      }
    }
  } */

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991px) {
      display: none;
  }
  @media (min-width: 992px) and (max-width: 1366px) {
    img{
      &.prof1 {
      width: 66%;
    }
    &.prof2 {
    right: 98px;
    width: 53%;
    }
    &.vetor1 {
      bottom: 184px;
      right: 36px;
    }
    &.vetor2 {
      right: 32px;
    }
    }
  }
`;
