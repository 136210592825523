import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import AboutTwo from "@/components/about-two";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import Blockquote from "@/components/blockquote";
import How from "@/components/how";
import Who from "@/components/who";
import HowToWork from "@/components/howToWork";
import Info from "@/components/info";
import Partners from "@/components/partners";
import Newsletter from "@/components/newsletter";

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="OPMbr - Olimpíada Brasileira dos Professores de Matemática do Ensino Médio no Brasil">
          <HeaderOne />
          <AboutTwo />
          <Blockquote />
          <How />
          <Who />
          <HowToWork />
          <Info />
          <Partners />
          <Newsletter />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
