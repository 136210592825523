/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "./layout.styles";

export const info = css`
  padding: 120px 0px 60px;

  h2{
    font-size: 40px;
    margin-bottom: 64px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 60px 0px;

    h2{
      font-size: 24px;
      line-height: 36px;
      margin: 32px 0;
    }

    p{
      font-size: 18px;
      line-height: 27px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }

  img{
    width: 100%;
  }
`;

export const blockTitle = css`

`;
