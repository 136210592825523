/** @jsx jsx */
import React from "react";
import { HowData } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from "@emotion/react";

import { info } from "../assets/styles/Info.styles";
import {
  secTitle,
  commonSection,
} from "../assets/styles/layout.styles";

const Info = () => {
  const { image } = HowData;
  return (
    <section id="tematicas" css={[commonSection, info]}>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <h2 css={secTitle}>Quais serão as temáticas das olimpíadas?</h2>
            <p>
              A Olimpíada busca selecionar os professores que tem paixão pelo
              ensino e pelo resultado de seus alunos, criando uma cultura de
              aprendizado contínuo e de crescimento pessoal.
            </p>
            <p>
              <strong>
                A avaliação vai se concentrar nos aspectos didáticos e humanos
                do ensino.
              </strong>
            </p>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 css={secTitle}>Como se preparar para a prova? </h2>
            <p>
              A prova da primeira fase está planejada para avaliar como o
              professor consegue entender os gaps de formação dos alunos e busca
              soluções simples, práticas e lúdicas de transferir o conhecimento.
              </p>
              <p>
                O resultado dos alunos é um elemento importante na pontuação,
                sendo medido por participação e premiação nas olímpiadas de
                alunos.
              </p>
              <p>
                Nas fases mais avançadas da premiação serão medidos o nível de
                envolvimento do professor dentro e fora da sala de aula para
                melhorar o aprendizado.
             
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Info;
