/** @jsx jsx */
import React from "react";
import { NewsletterData } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from "@emotion/react";

import {
  newsletter,
  pr79,
} from "../assets/styles/Newsletter.styles";
import {
  secTitle,
  commonSection,
  commonBtn,
  yellowBg,
} from "../assets/styles/layout.styles";

const Newsletter = () => {
  const { sectionContent, button, vetor } = NewsletterData;
  return (
    <section id="como_estudar" css={[commonSection, newsletter]}>
      <Container>
        <Row>
          <Col css={pr79} lg={6} md={12} sm={12}>
            <img
              src={sectionContent.imgMobile}
              className="professores_mobile"
            />
            <h2 css={[secTitle]}>{sectionContent.title}</h2>
            <Form>
              <Form.Group className="mb-3" controlId="nome">
                <Form.Control type="text" placeholder="Nome" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Control type="email" placeholder="Melhor email" />
              </Form.Group>

              <Button css={[commonBtn, yellowBg]} type="submit">
                {button.label}
              </Button>
            </Form>
            {/* <a css={[commonBtn,redBg]} href={button.url}> 
              <span>{button.label}</span>
            </a> */}
          </Col>
        </Row>
      </Container>

      <img data-aos-offset={"0"}
          data-aos={"fade"} 
          data-aos-duration={"1000"}  
          data-aos-delay={"400"} 
          data-aos-once={true} src={sectionContent.lwb} alt="" className="lwb" />

      <img
      data-aos-offset={"0"}
      data-aos={"fade"} 
      data-aos-duration={"1000"}  
      data-aos-delay={"400"} 
      data-aos-once={true} src={sectionContent.rwb} alt="" className="rwb" />

      <img data-aos-offset={"0"}
          data-aos={"fade"} 
          data-aos-duration={"1000"}  
          data-aos-delay={"400"} 
          data-aos-once={true} src={sectionContent.img1} alt="" className="books" />
    </section>
  );
};

export default Newsletter;
