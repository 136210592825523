/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "./layout.styles";

export const blockQuote = css`
  background-color: ${colors.yellow};
  padding: 120px 90px;

  .smallBQ{
    margin-left: 10px;
  }

  p{
    width: 75%;
  }

  .row{
    div{
        display: flex;
        align-items: center;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .smallBQ{
      position: absolute;
      right: 0;
      bottom:0;
    }
    padding: 70px 0px 50px;
    p{
      width: 100%;
      font-size: 18px;
      line-height: 27px;
      margin-top: 64px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;

export const blockTitle = css`
  position: relative;
  display: flex;
  align-items: center;

  .vector{
    position: absolute;
    left: 0;
  }

  .blockquote{
    position: absolute;
    left: 116px;
    z-index: 0;
    top: -67px;
  }

  h2 {
    color: ${colors.dark};
    z-index: 1;
    padding-left: 180px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2{
      padding: 0;
      font-size: 20px;
      line-height: 24px;
      padding-left: 60px;
    }
    .vector{
      width: 54px;
      left: -15px;
    }
    .blockquote{
      width: 57px;
      top: -20px;
      left: 47px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;
