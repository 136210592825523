/** @jsx jsx */
import React from "react";
import { AboutTwoData } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from '@emotion/react'

import { 
  abAgency,
  pr79,
  abImg
}  from '../assets/styles/AboutTwo.styles'
import {
  secTitle,
  secDesk,
  commonSection,
  commonBtn,
  Titleheader
} from "../assets/styles/layout.styles";

const AboutTwo = () => {
  const { sectionContent, button, vetor } = AboutTwoData;
  return (
    <section css={[commonSection,abAgency]}>
      <Container>
        <Row>
          <Col css={pr79} lg={6} md={12} sm={12}
           data-aos-offset={"0"}
           data-aos={"fade"} 
           data-aos-duration={"1000"}  
           data-aos-delay={"300"} 
           data-aos-once={true} 
          >
            {/* <img src={sectionContent.logo} alt="logo"/> */}
            <img src={sectionContent.imgMobile} className="professores_mobile"/>
            <h2 css={[Titleheader]}>{sectionContent.title}</h2>
            <p css={secDesk}>{sectionContent.text}</p>
            <Form>

      
     
      <a href='#' css={commonBtn}>
        INSCRIÇÕES ENCERRADAS!
      </a>
    </Form>
            {/* <a css={[commonBtn,redBg]} href={button.url}> 
              <span>{button.label}</span>
            </a> */}
          </Col>
          {/* <Col lg={6} md={6} sm={12}>
            <div css={abImg}>
              <img src={sectionContent.img1} alt="" className="prof1" />
              <img src={sectionContent.img2} alt="" className="prof2" />
            </div>
          </Col> */}
        </Row>
      </Container>
      <div css={abImg}               
          data-aos-offset={"0"}
          data-aos={"fade"} 
          data-aos-duration={"1000"}  
          data-aos-delay={"400"} 
          data-aos-once={true} 
      >
              <img src={sectionContent.img1} 
                alt="" className="prof1" />
              <img src={sectionContent.img2} alt="" className="prof2" />
              <img src={vetor.vetor1} alt="" className="vetor1" />
              <img src={vetor.vetor3} alt="" className="vetor3" />
              <img src={vetor.vetor2} alt="" className="vetor2" />
            </div>
    </section>
  );
};

export default AboutTwo;
