/** @jsx jsx */
import React from "react";
import { HowToWorkData, HowToWorkImage } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from "@emotion/react";

import { 
    howToWork, 
    faseCard,
    faseDate,
    fase,
    imgFases,
    tag
 } from "../assets/styles/HowToWork.styles";
import {
  secTitle,
  commonSection,
} from "../assets/styles/layout.styles";

const HowToWork = () => {
  return (
    <section id="como" css={[commonSection, howToWork]}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h2 css={secTitle}>Como vai funcionar?</h2>       
          </Col>
          <Col lg={5} md={5} sm={12}>
              <p>Será <strong>uma seleção em 3 fases</strong> com medalhas em cada uma delas.</p> 
          </Col>
        </Row>
        <Row>
          <Col lg={5} md={5} sm={12}>
         
            {HowToWorkData.map(({ image, text, date }, index) => (
              <div css={faseCard}
                data-aos-offset={"0"}
                data-aos={"fade-down"}
                data-aos-delay={(index+1)*150}
                data-aos-duration={1200}
                data-aos-once={true}
              >
              
                    <div css={fase}>
                       <img src={image} alt={`fase-"${index}`}/>
                       <p>
                        {text}
                       </p>
                    </div>
            
                <div css={faseDate}>
                    <span>
                    {date}
                    </span>
                </div>
              </div>
            ))}

            <div css={tag}>
                <img src={HowToWorkImage.img2} />
                <p>
                    Os vencedores da <strong>medalha de ouro</strong> irão receber o prêmio da <strong>viagem internacional</strong>
                </p>
            </div>
          </Col>   
        </Row>
      </Container>
      <div css={imgFases}>
              <img src={HowToWorkImage.img1} />
          </div>
    </section>
  );
};

export default HowToWork;
