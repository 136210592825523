/** @jsx jsx */
import React from "react";
import { HowData } from "@/data";
import Video from "@/components/video";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from '@emotion/react'

import {
how
} from "../assets/styles/How.styles";
import {
    secTitle,
    commonSection,
  } from "../assets/styles/layout.styles";

  const How = () => {
    const { image } = HowData;
    return (
      <section id="sobre" css={[commonSection,how]}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>            
                <div>
                  <h2 css={secTitle}>Como Surgiu?</h2>
                  <p>
                    O Brasil faz parte da elite da Matemática mundial (nível 5 da IMU) 
                    mas ficou na posição 72 de 79 países no PISA 2018. 
                  </p>

                  <p>
                    Matemática é o pilar de desenvolvimento das nações avançadas ou em 
                    desenvolvimento acelerado.
                  </p>

                  <p>
                    A economia brasileira está avançando em velocidade menor que os demais 
                    países em desenvolvimento, e uma das causas está na baixa qualidade da educação.
                  </p>

                  <p>
                    Um grupo de engenheiros do ITA se debruçou sobre esta questão e 
                    coordenou a criação desta premiação.
                  </p>
                </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
                <img 
                data-aos-offset={"0"}
                data-aos={"fade"} 
                data-aos-duration={"1000"}  
                data-aos-delay={"300"} 
                data-aos-once={true}
                
                src={image} alt="Resultado PISA 2018" />
            </Col>
          </Row>

          <Row>
          <Video
            videoSrcURL="https://www.youtube.com/embed/TyzFJDEAqBM"
            videoTitle="Official Music Video on YouTube"
          />
          </Row>
        </Container>
      </section>
    );
  };
  
  export default How;